import { render, staticRenderFns } from "./NsAside.vue?vue&type=template&id=5bb6ae32&scoped=true"
import script from "./NsAside.vue?vue&type=script&lang=js"
export * from "./NsAside.vue?vue&type=script&lang=js"
import style0 from "./NsAside.vue?vue&type=style&index=0&id=5bb6ae32&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb6ae32",
  null
  
)

export default component.exports