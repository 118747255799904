export default {

	// api请求地址
	baseUrl: BASE.API,
	// baseUrl: 'http://dyh.eapower.com.cn',
	// baseUrl: 'https://v5.niuteam.cn',

	// 图片域名
	imgDomain: BASE.API,
	// imgDomain: 'http://dyh.eapower.com.cn',

	// 腾讯地图key
	 mpKey: '',

	// 客服
	 webSocket: '',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}
